<template>
  <el-main>
    <div class="product-intro">
      <div class="intro-section" :style="{ backgroundImage: `url(${require('@/assets/product_first_bg.png')})` }">
        <p class="intro-subtitle">中小企业</p>
        <h1 class="intro-title">数智化管理服务平台</h1>
        <p class="intro-description">助力中小企业数字化转型 赋能管理团队智能化决策</p>
        <div class="carousel-buttons">
          <el-button class="trial-button">免费试用</el-button>
          <el-button class="consult-button">立即咨询</el-button>
        </div>
      </div>
      <ProductSelectSection :options="features" @select-option="handleSelectOption"/>
      <component :is="currentComponent"></component>
    </div>
  </el-main>
</template>

<script>
import ProductSelectSection from "./ProductSelectSection.vue";
import IntegratedManagementContent from "./IntegratedManagement/IntegratedManagementContent.vue";
import BigDataAnalysisContent from "./BigDataAnalysis/BigDataAnalysisContent.vue";
import BossAssistantContent from "./BossAssistant/BossAssistantContent.vue";

export default {
  name: "ProductIntroContent",
  components: {
    ProductSelectSection,
    IntegratedManagementContent,
    BossAssistantContent,
    BigDataAnalysisContent,
  },
  data() {
    return {
      features: [
        {
          icon: require('@/assets/value_icon1.png'),
          title: '一体化管理系统',
          description: '客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。',
          url: '/product-intro#integrated-management'
        },
        {
          icon: require('@/assets/value_icon2.png'),
          title: 'BOSS助手',
          description: '客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。',
          url: '/product-intro#boss-assistant'
        },
        {
          icon: require('@/assets/value_icon3.png'),
          title: '大数据分析平台',
          description: '客户可根据自身行业痛点和特性，配置个性化的行业应用，满足企业个性化管理需求。',
          url: '/product-intro#big-data-analysis'
        }
      ],
      currentComponent: 'IntegratedManagementContent'
    }
  },
  mounted() {
    this.updateComponentFromHash();
    window.addEventListener('hashchange', this.updateComponentFromHash);
  },
  beforeUnmount() {
    window.removeEventListener('hashchange', this.updateComponentFromHash);
  },
  methods: {
    handleSelectOption(index) {
      const components = ['IntegratedManagementContent', 'BossAssistantContent', 'BigDataAnalysisContent'];
      this.currentComponent = components[index];
      const hashes = ['#integrated-management', '#boss-assistant', '#big-data-analysis'];
      window.location.hash = hashes[index];
    },
    updateComponentFromHash() {
      const hash = window.location.hash;
      const hashToComponent = {
        '#integrated-management': 'IntegratedManagementContent',
        '#boss-assistant': 'BossAssistantContent',
        '#big-data-analysis': 'BigDataAnalysisContent'
      };
      this.currentComponent = hashToComponent[hash] || 'IntegratedManagementContent';
    }
  }
}
</script>

<style scoped>
.product-intro {
  width: 100%;
  text-align: center;
  padding: 60px 0;
}

.intro-section {
  background-size: cover;
  background-position: center;
  padding-top: 60px;
  padding-bottom: 120px;
}

.intro-subtitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 52.13px;
  color: rgba(128, 128, 128, 1);
  margin-bottom: 20px;
}

.intro-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 69.5px;
  background: linear-gradient(90deg, rgba(22, 80, 139, 1) 0%, rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.intro-description {
  font-size: 24px;
  font-weight: 400;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  margin-bottom: 60px;
}

.carousel-buttons {
  margin: 20px auto; /* 上下间距20px，水平居中 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  gap: 10px; /* 按钮之间的间距 */
}


.trial-button {
  width: 210px;
  height: 67px;
  font-size: 20px;
  opacity: 1;
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  border-radius: 33.33px;
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.consult-button {
  width: 210px;
  height: 67px;
  font-size: 20px;
  opacity: 1;
  border-radius: 44.44px;
  background: rgba(240, 242, 245, 1);
  color: rgba(128, 128, 128, 1);
}
</style>
