<template>
  <CenteredTextImageSection/>
  
</template>

<script>
import CenteredTextImageSection from "@/components/sections/CenteredTextImageSection.vue";

export default {
  name: 'HelpCenterContent',
  components: {CenteredTextImageSection}
}
</script>

<style scoped>
</style>
