<template>
  <section class="boss-assistant-section">
    <div class="title-container">
      <img
          src="@/assets/boss_assist_before.png"
          alt="Background"
          class="title-background"
      />
      <h2 class="section-title">BOSS助手 AI赋能</h2>
    </div>
    <div class="section-sub-title">一段介绍文字一段介绍文字</div>
    <div class="horizontal-scroll-container" ref="scrollContainer">
      <div class="horizontal-scroll-content" ref="scrollContent">
        <div class="scroll-item" v-for="(item, index) in extendedImages" :key="index">
          <img :src="item" class="scroll-image"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BossAssistantSection",
  data() {
    return {
      images: [
        require('@/assets/ai_intro_pic_1.png'),
        require('@/assets/ai_intro_pic_2.png'),
        require('@/assets/ai_intro_pic_3.png'),
        require('@/assets/ai_intro_pic_4.png')
      ],
      scrollInterval: null
    };
  },
  computed: {
    extendedImages() {
      // 将图片列表复制一份，实现无缝滚动
      return [...this.images, ...this.images];
    }
  },
  mounted() {
    this.startAutoScroll();
  },
  beforeUnmount() {
    this.stopAutoScroll();
  },
  methods: {
    startAutoScroll() {
      const container = this.$refs.scrollContainer;
      const content = this.$refs.scrollContent;
      const itemWidth = container.scrollWidth / this.extendedImages.length;

      this.scrollInterval = setInterval(() => {
        if (container.scrollLeft + container.clientWidth >= content.clientWidth) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += 2; // 调整此值以控制滚动速度
        }

        // 当滚动到副本部分时，瞬间跳回到原始部分
        if (container.scrollLeft >= itemWidth * this.images.length) {
          container.scrollLeft = 0;
        }
      }, 20); // 调整此值以控制滚动速度
    },
    stopAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }
    }
  }
};
</script>

<style scoped>
.boss-assistant-section {
  background-image: url("@/assets/BossAssistantSection_bg_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-bottom: 84px;
}

.title-container {
  width: 100%;
  height: 366px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title-background {
  position: absolute;
  width: 995.38px;
  top: 0px;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 140px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-sub-title {
  display: inline-block; /* 保证元素是块级元素以便居中 */
  opacity: 1;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 52.13px;
  color: rgba(128, 128, 128, 1);
  text-align: center; /* 水平居中 */
  vertical-align: top;
  margin-top: -80px;
}

.horizontal-scroll-container {
  width: 100%;
  overflow-x: auto; /* 启用水平滚动 */
  white-space: nowrap; /* 保持子元素在同一行 */
  padding: 10px 0; /* 上下内边距 */
  padding-bottom: 134px;
  scrollbar-width: none; /* Firefox */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari 和 Opera */
}

.horizontal-scroll-content {
  display: inline-block; /* 使内容在一行内显示 */
}

.scroll-item {
  display: inline-block; /* 使每个滚动项在一行内显示 */
  margin-right: 62px; /* 每个图片之间的间距 */
}

.scroll-image {
  width: 982px;
  height: 591px;
  object-fit: cover; /* 保持图片比例 */
  border-radius: 10px; /* 图片圆角 */
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  opacity: 1;
}
</style>
