<template>
  <div class="product-select-section">
    <div class="product-select-option" v-for="(option, index) in options" :key="index"
         @click="selectOption(index)"
         :class="{ 'is-selected': isSelected(index) }">
      <img :src="option.icon" alt="Icon" class="option-icon">
      <div class="option-title">{{ option.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSelectSection',
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentHash: window.location.hash
    };
  },
  methods: {
    selectOption(index) {
      this.$emit('select-option', index);
    },
    isSelected(index) {
      const hash = this.currentHash;
      if (hash === "" && index === 0) return true
      console.log(hash.substring(1))
      console.log(this.options[index].url.split('#')[1])
      return hash.substring(1) === this.options[index].url.split('#')[1];
    },
    updateHash() {
      this.currentHash = window.location.hash;
    }
  },
  mounted() {
    // 监听hash变化事件
    window.addEventListener('hashchange', this.updateHash);
  },
  beforeUnmount() {
    // 移除hash变化事件监听器
    window.removeEventListener('hashchange', this.updateHash);
  }
}
</script>

<style scoped>
.product-select-section {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -100px;
  box-sizing: content-box;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-select-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 1);
  padding-top: 20px;
  width: 346px;
  height: 173px;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.is-selected {
  background: rgba(65, 167, 240, 1);
}

.is-selected .option-title {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
  font-size: 24px;
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.option-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.option-title {
  font-size: 24px;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(22, 80, 139, 1) 0%, rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.option-description {
  font-size: 16px;
  font-weight: 400;
  color: rgba(50, 62, 77, 1);
  margin-bottom: 20px;
}

.option-next-icon {
  width: 40px;
  height: 40px;
}
</style>
