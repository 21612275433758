<template>
  <div>
    <AppHeader/>
    <CenteredTextImageSection/>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import AppFooter from '../components/AppFooter.vue'
import CenteredTextImageSection from "@/components/sections/FAQSection.vue";

export default {
  name: 'AboutUsView',
  components: {
    CenteredTextImageSection,
    AppHeader,
    AppFooter
  }
}
</script>
