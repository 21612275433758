<template>
  <div class="right-image-intro">
    <div class="section-bg"></div>
    <div class="box">
      <div class="text-content">
        <div class="small-title">高效，值得信任的</div>
        <div class="main-title">一体化企业管理系统</div>
        <div class="sub-title">
        </div>
        <div class="description">
          电脑端和移动端多端适配<br/>
          围绕企业独特需求进行二次开发<br/>
          专业团队为企业提供定制服务
        </div>
      </div>
      <div class="image-content">
        <img
            src="@/assets/product_image_intro_1.png"
            alt="Product Image"
            class="product-image"
        />
        <img src="@/assets/product_phone_image_intro_1.png" alt="phone Image" class="phone-image"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightImageIntro",
};
</script>

<style scoped>
.right-image-intro {
  width: 100%;
  height: 950px;
  position: relative;
  /* border: 1px solid #000; */
}

.section-bg {
  z-index: 0;
  width: 100%;
  height: 950px;
  background-image: url("@/assets/product_first_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  top: -50px;
  position: absolute;
}

.box {
  z-index: 0;
  top: 160px;
  position: absolute;
  right: 0px;
  left: 0px;
  margin: auto;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  max-width: 1660px;
  min-width: 1660px;
  margin: 0 auto;
}

.text-content {
  z-index: 2;
  text-align: left;
  margin-top: 20px;
}

.small-title {
  font-size: 36px;
  font-weight: 700;
  color: rgba(128, 128, 128, 1);
}

.main-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 69.5px;
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
}

.description {
  margin-top: 180px;
  font-size: 24px;
  font-weight: 400;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
}

.product-image {
  width: 982px;
  height: 591px;
  border-radius: 24.52px;
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.image-content {
  position: relative;
}

.phone-image {
  z-index: 3;
  position: absolute;
  top: 200px;
  left: -120px;
  width: 237px;
  height: 488px;
  border-radius: 35px;
  //background: rgba(255, 255, 255, 1);
  //box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
}
</style>
