<template>
  <div>
    <CenteredTextImageSection/>
    <RightImageIntro/>
    <centered-text-image-section2/>
  </div>
</template>

<script>

import {defineComponent} from "vue";
import CenteredTextImageSection from "./CenteredTextImageSection.vue";
import RightImageIntro from "@/components/ProductIntro/IntegratedManagement/RightImageIntro.vue";
import CenteredTextImageSection2 from "./CenteredTextImageSection2.vue";


export default defineComponent({
  components: {CenteredTextImageSection, RightImageIntro, CenteredTextImageSection2}
})
</script>

<style scoped>

</style>
