<template>
  <div>
    <div class="top-style">
      <img src="../../assets/help_center_top.png" alt="Help Center Image"
        style="width: 100%; height: auto;" />
        <div class="top-text"><span>浏览所有分类</span></div>
    </div>

    <section class="centered-text-image-section">

      <el-row :gutter="20">
        <el-col :span="8" v-for="(card, index) in cardsData" :key="index" class="cards">
          <el-card style="max-width: 400px" class="card">
            <template #header>
              <div class="card-header" @click="openUrl(card.header)">
                <img :src="card.image" alt="" class="card-img">
                <span class="truncate">{{ card.name }}</span>
              </div>
            </template>

            <div style="height: 150px;">
              <li v-for="(item, i) in card.items" :key="i" class="text item" @click="openUrl(item.url)">
                {{ item.text }}
              </li>
            </div>


            <template #footer>
              <div class="card-footer" @click="openUrl(card.footer)">
                <span class="footer-link">查看更多</span>
                <img src="../../assets/Icon/svg_right.png" alt="" class="card-footer-img">
              </div>

            </template>
          </el-card>
        </el-col>
      </el-row>

    </section>
  </div>
</template>

<script>
export default {
  name: 'CenteredTextImageSection',
  data() {
    return {
      // 模拟从 Java 后端获取的数据
      cardsData: [
        {
          name: '新手快速入门',
          header: 'https://gsap.com/',
          image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/nav1YS?rand=439316599',
          items: [
            { text: '快速了解我们', url: 'https://example.com/1-1' },
            { text: '基础操作指南', url: 'https://example.com/1-2' },
          ],
          footer: 'https://gsap.com/',
        },
        {
          name: 'BOSS应用手册',
          header: 'https://gsap.com/',
          image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/Bb3oJ2?rand=1226292161',
          items: [
            { text: '工作平台常见问题', url: 'https://example.com/3-1' },
            { text: '客户管理常见问题', url: 'https://example.com/3-2' },
            { text: '外勤管理常见问题', url: 'https://example.com/3-3' },
            { text: '销售管理常见问题', url: 'https://example.com/3-4' },
            { text: '用户与权限管理常见问题', url: 'https://example.com/3-4' },

          ],
          footer: 'https://gsap.com/',
        },
        {
          name: '员工应用手册',
          header: 'https://gsap.com/',
          image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/nav1YS?rand=439316599',
          items: [
            { text: '设计部门常见问题', url: 'https://example.com/1-1' },
            { text: '市场部门常见问题', url: 'https://example.com/1-2' },
            { text: '采购部门常见问题', url: 'https://example.com/1-3' },
            { text: '仓管部门常见问题', url: 'https://example.com/1-4' },
            { text: '生成部门常见手册', url: 'https://example.com/1-4' },
          ],
          footer: 'https://gsap.com/',
        },
        {
          name: '系统后台设置应用手册',
          header: 'https://gsap.com/',
          image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/pdy962?rand=1410214307',
          items: [
            { text: '用户和权限管理', url: 'https://example.com/3-1' },
            { text: '系统管理', url: 'https://example.com/3-2' },
            { text: '组织架构管理', url: 'https://example.com/3-3' },
            { text: '角色及权限管理', url: 'https://example.com/3-4' },
          ],
          footer: 'https://gsap.com/',
        },

        {
          name: '数据安全应用手册',
          header: 'https://gsap.com/',
          image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/Bb3oJ2?rand=1226292161',
          items: [
            { text: '数据库远程备份方法', url: 'https://example.com/2-1' },
            { text: '数据库备份和恢复的常见问题', url: 'https://example.com/2-2' },
            { text: '如何为灵当CRM网站添加https加密支持', url: 'https://example.com/2-3' },
          ],
          footer: 'https://gsap.com/',
        },
        // {
        //   name: '系统操作应用手册',
        //   header: 'https://gsap.com/',
        //   image: 'https://resource.helplook.net/docker_production/ol2bzm/content_icon/pdy962?rand=1410214307',
        //   items: [
        //     { text: '工作平台常见问题', url: 'https://example.com/3-1' },
        //     { text: '客户管理常见问题', url: 'https://example.com/3-2' },
        //     { text: '外勤管理常见问题', url: 'https://example.com/3-3' },
        //     { text: '销售管理常见问题', url: 'https://example.com/3-4' },
        //     { text: '用户与权限管理常见问题', url: 'https://example.com/3-4' },
        //   ],
        //   footer: 'https://gsap.com/',
        // },
      ],
    };
  },
  methods: {
    openUrl(url) {
      console.log('Opening URL:', url);
      // 打开一个新的页面
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped>
.top-style {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}
.top-text{
  margin-left: 10%;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  font-weight: 400;
}

.centered-text-image-section {

  width: 80%;
  margin-left: 10%;

}


.cards {
  height: 300px;
  margin-bottom: 100px;
}

.item {
  cursor: pointer;
  transition: color 0.3s;
  font-size: 0.9rem;
  line-height: 1.75rem;
}

.item:hover {
  color: #409eff;
}

.card-header {
  font-size: 1.25rem;
  line-height: 1.75rem;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.card-img {
  height: auto;
  width: 10%;
  margin-right: .5rem;
}

.truncate {
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* 添加阴影 */
  transition: box-shadow 0.3s;
  /* 添加过渡效果 */
}

.card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* 悬停时阴影变大 */
}

.card-footer {
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.0rem;
  align-items: center;
  cursor: pointer;
  display: flex;
}

.footer-link {
  cursor: pointer;
  color: #409eff;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #66b1ff;
}

.card-footer-img {
  height: auto;
  width: 5%;
  margin-right: .5rem;
}

.card-footer:hover .footer-link {
  margin-right: 10px;
  transition: 0.5s;
  /* 添加过渡效果 */
}
</style>
