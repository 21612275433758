<template>
  <section class="boss-assistant-section">
    <div class="title-container">
      <img src="@/assets/boss_assist_before.png" alt="Background" class="title-background" />
      <h2 class="section-title">BOSS助手 AI赋能</h2>
    </div>
    <div class="content-container">
      <div class="left-content">
        <h3 class="left-title">BOSS助手</h3>
        <h4 class="left-subtitle">AI助理，全链路赋能管理</h4>
        <p class="left-description">
          BOSS助手通过高度定制化的服务和高效的多场景交互能力，针对企业运营中的各类需求提供实时的数据支持和日常任务的智能处理。此外，它还提供基于数据的决策分析工具，帮助管理者解读业务运营状况，优化决策过程。
        </p>
        <el-button class="trial-button">免费试用</el-button>
      </div>
      <div class="right-content">
        <div class="image-container">
          <img src="@/assets/boss_image_bg.png" alt="Background Image" class="background-image" />
          <img src="@/assets/boss_image.png" alt="Foreground Image" class="foreground-image" />
        </div>
      </div>
    </div>
    <!-- <div class="bottom-images">
      <img
        src="@/assets/boss_bottom_left.png"
        alt="Left Bottom"
        class="bottom-left"
      />
      <img
        src="@/assets/boss_bottom_right.png"
        alt="Right Bottom"
        class="bottom-right"
      />
    </div> -->
  </section>
</template>

<script>
export default {
  name: "BossAssistantSection",
};
</script>

<style scoped>
.boss-assistant-section {
  background: rgba(247, 249, 250, 1);
  text-align: center;
  padding-bottom: 84px;
}

.title-container {
  width: 100%;
  height: 366px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.title-background {
  position: absolute;
  width: 995.38px;
  top: 0px;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 140px;
  width: 100%;
}

.content-container {
  width: 100%;
  height: 632px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-content {
  flex: 1;
  height: 100%;
  padding: 60px 80px 120px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
}

.left-title {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28.96px;
  background: linear-gradient(90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
}

.left-subtitle {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 46.34px;
  color: rgba(56, 56, 56, 1);
  margin: 0px;
}

.left-description {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(56, 56, 56, 1);
  margin: 0px;
  margin-top: 80px;
  flex: 1 1 auto;
  text-align: left;
}

.trial-button {
  width: 210px;
  height: 67px;
  border-radius: 33.33px;
  background: linear-gradient(90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%);
  box-shadow: 0px 1.39px 4.17px rgba(96, 108, 128, 0.05);
  color: #fff;
}

.right-content {
  flex: 1;
  position: relative;
}

.image-container {
  width: 979px;
  height: 632px;
}

.background-image {
  width: 979px;
  height: 632px;
  object-fit: cover;
}

.foreground-image {
  position: absolute;
  width: 979px;
  height: 632px;
  top: 7px;
  left: 3px;
}

.bottom-images {
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
}

.bottom-left {
  width: 493.88px;
  height: 544px;
}

.bottom-right {
  width: 1124.67px;
  height: 549.82px;
}
</style>
