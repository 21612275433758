<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  watch: {
    '$route'(to) {
      document.title = to.meta.title || '易企创';
    }
  },
  mounted() {
    document.title = this.$route.meta.title || '易企创';
  }
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
</style>
