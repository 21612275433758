<template>
  <el-header class="app-header" fixed>
    <div class="header-content">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :default-active="activeIndex"
      >
        <el-menu-item index="1" @click="onClickRouter('/')">
          首页
        </el-menu-item>
        <el-sub-menu index="2" :collapse-transition=true :popper-class="popper" :close-on-click-outside=false>
          <template #title>
            <span>产品介绍</span>
          </template>
          <el-menu-item index="2-1" @click="onClickRouter('/product-intro#integrated-management')">
            一体化管理系统
          </el-menu-item>
          <el-menu-item index="2-2" @click="onClickRouter('/product-intro#boss-assistant')">
            BOSS助手
          </el-menu-item>
          <el-menu-item index="2-3" @click="onClickRouter('/product-intro#big-data-analysis')">
            大数据分析平台
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="6" @click="onClickRouter('/purchase-service')">
          解决方案
        </el-menu-item>
        <el-menu-item index="3" @click="onClickRouter('/help-center')">
          帮助中心
        </el-menu-item>
        <el-menu-item index="4" @click="onClickRouter('/about-us')">
          关于我们
        </el-menu-item>
      </el-menu>
      <div class="login-buttons">
        <el-button class="trial-button">免费试用</el-button>
        <el-button class="login-button" @click="onClickLogin">注册/登录</el-button>
      </div>
    </div>
  </el-header>
</template>

<script>
const mapIndex = {
  "/": "1",
  "/product-intro": "2-1",
  "/product-intro#integrated-management": "2-4",
  "/product-intro#boss-assistant": "2-2",
  "/product-intro#big-data-analysis": "2-3",
  "/help-center": "3",
  "/about-us": "4",
};
export default {
  data() {
    return {
      activeIndex: "",
    };
  },
  mounted() {
    this.activeIndex = mapIndex[this.$route.path] || "1";
  },
  methods: {
    onClickRouter(path) {
      if (path.includes('#')) {
        window.location.href = path;
      } else {
        this.$router.push({path: path});
      }
    },
    onClickLogin() {
      window.location.href = 'https://portal.yqchuang.com.cn/';
    }
  },
};
</script>

<style scoped>
.app-header {
  background: rgba(255, 255, 255, 1); /* 设置背景色并带有透明度 */
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  border-radius: 20px;
  height: 112px;
  width: 100vw;
  min-width: 1660px;
  display: flex;
  align-items: center;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 1200px;
  max-width: 1660px;
  width: 73vw;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 202px;
  height: 83px;
}

.el-menu-demo {
  flex-grow: 1;
  display: flex;
  justify-content: center; /* 居中菜单 */
  line-height: 60px;
  height: 112px;
  border-bottom: none !important;
  flex: 0 0 auto;
  width: 60%;
}

.el-menu-item {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: #414552;
  text-align: center;
  vertical-align: middle;
}

.el-sub-menu span {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: #414552;
  text-align: center;
  vertical-align: middle;
}

.el-menu-item a {
  line-height: 112px;
  height: 112px;
}

.el-menu-item.is-active {
  border-width: 4px !important;
}

.el-popper .el-menu--horizontal .el-menu .el-menu-item {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: #414552;
  text-align: center;
  vertical-align: middle;
  padding: 24px;
}

.el-sub-menu .el-menu-item:not(:last-child) {
  border-bottom: 1px solid #ebeef5;
}

.login-buttons {
  display: flex;
  align-items: center;
  overflow: visible;
}

.trial-button {
  width: 100px;
  height: 60px;
  opacity: 1;
  border-radius: 9px;
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 0.71) 0%,
      rgba(35, 133, 198, 0.71) 100%
  );
  box-shadow: 0px 10px 25px rgba(65, 167, 240, 0.4);
  color: #fff;
}

.login-button {
  width: 100px;
  height: 60px;
  opacity: 1;
  border-radius: 9px;
  background: rgba(233, 235, 240, 1);
  box-shadow: 0px 10px 25px rgba(16, 30, 54, 0.1);
  color: #000;
  margin-left: 10px;
}
</style>
