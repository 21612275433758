<template>
  <el-main>
    <CarouselSectionMainPage />
    <!-- <div class="section-container">
      <CenteredTextImageSection />
    </div> -->
    <h2 class="section-title">五大核心引擎 统一数据中心 赋能价值创造</h2>
    <img src="@/assets/ad_bg.png" style="width: 1000px; display: block; margin: 20px auto;" alt="ad background" />

    
    <!-- <ValueSection /> -->
    <div class="section-bg-gray">
      <div class="section-container-max-big">
        <WhyChooseSection />
      </div>
    </div>
    
    <div class="section-bg-gray">
      <div class="section-container-min-big">
        <BossAssistantSection />
      </div>
    </div>

    <!-- <div class="section-container-min-big">
      <FAQSection />
    </div> -->
  </el-main>
</template>

<script>
// import CenteredTextImageSection from "./sections/CenteredTextImageSection.vue";
// import ValueSection from "./sections/ValueSection.vue";
import WhyChooseSection from "./sections/WhyChooseSection.vue";
import BossAssistantSection from "./sections/BossAssistantSection.vue";
// import FAQSection from "./sections/FAQSection.vue";
import CarouselSectionMainPage from "@/components/sections/CarouselSectionMainPage.vue";

export default {
  components: {
    CarouselSectionMainPage,
    // CenteredTextImageSection,
    // ValueSection,
    WhyChooseSection,
    BossAssistantSection,
    // FAQSection,
  },
};
</script>

<style scoped>
.el-main {
  padding: 0px;
  overflow: initial;
  width: 100vw;
  min-width: 1660px;
}

.page-main-container {
  width: 100vw;
  /* border: 1px solid #000; */
  margin: 0px auto;
}

.section-container {
  width: 100vw;
  min-width: 1200px;
  max-width: 1660px;
  margin: 0px auto;
  /* border: 1px solid #000; */
}

.section-bg-gray {
  width: 100%;
  background: rgba(247, 249, 250, 1);
}

.section-container-max-big {
  width: 100vw;
  min-width: 1200px;
  max-width: 1660px;
  /* border: 1px solid #000; */
  margin: 0px auto;
  box-sizing: border-box;
}

.section-container-min-big {
  width: 100vw;
  min-width: 1660px;
  max-width: 1660px;
  /* border: 1px solid #000; */
  margin: 0px auto;
  box-sizing: border-box;
}
.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 140px;
  width: 100%;
}
</style>
