<template>
  <el-footer class="footer-section">
    <div class="footer-content">
      <div class="footer-logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <div class="footer-links">
        <div class="footer-column">
          <h3>产品介绍</h3>
          <ul>
            <li><a href="/product-intro#integrated-management">一体化管理系统</a></li>
            <li><a href="/product-intro#boss-assistant">BOSS助手</a></li>
            <li><a href="/product-intro#big-data-analysis">大数据分析平台</a></li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>探索</h3>
          <ul>
            <li><a href="/purchase-service">解决方案</a></li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>帮助中心</h3>
          <ul>
            <li>常见问题</li>
            <li>产品使用手册</li>
          </ul>
        </div>
        <div class="footer-column">
          <h3>关于我们</h3>
          <ul>
            <li>团队</li>
            <li>联系我们</li>
          </ul>
          <h3>移动端APP下载</h3>
          <div class="download-buttons">
            <a href="https://yqchuang.com.cn/dist/4_base.apk" download>
              <img src="@/assets/android.png" alt="Android"/>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>
        &copy; Copyright 2008-2024 南京探智信息科技有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024120258号-1</a>
        苏公网安备31011502003299号
      </p>
    </div>
  </el-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer-section {
  width: 100%;
  min-width: 1660px;
  background-color: #f7f9fa;
  padding: 0;
  text-align: left;
  height: fit-content;
}

.footer-content {
  width: 100%;
  min-width: 1200px;
  max-width: 1660px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 90px 0px;
  box-sizing: border-box;
  margin: 0 auto;
}

.footer-logo {
  width: 360px;
}

.footer-logo img {
  width: 161px;
  height: 66px;
  margin-top: 10px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 40px;
}

.footer-column {
  margin-right: 40px;
}

.footer-column h3 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  font-size: 18px;
  font-weight: 500;
  color: rgba(81, 75, 129, 1);
  margin-bottom: 18px;
}

.footer-column ul li a {
  text-decoration: none;
  color: rgba(81, 75, 129, 1);
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.download-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.download-buttons img {
  width: 120px;
  height: auto;
}

.footer-bottom {
  height: 119px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(81, 75, 129, 1);
  text-align: center;
  border-top: 1.35px solid rgba(206, 206, 234, 1);
}
</style>
