import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductIntroView from '../views/ProductIntroView.vue'
import HelpCenterView from '../views/HelpCenterView.vue'
// import PurchaseServiceView from '../views/PurchaseServiceView.vue'
// import IntegratedManagement from '../views/ProductIntro/IntegratedManagement.vue'
// import BossAssistant from '../views/ProductIntro/BossAssistant.vue'
// import BigDataAnalysis from '../views/ProductIntro/BigDataAnalysis.vue'
import AboutUsView from "@/views/AboutUsView.vue";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        meta: {title: '首页'}
    },
    {
        path: '/product-intro',
        name: 'ProductIntroView',
        component: ProductIntroView,
        meta: {title: '产品介绍'}
    },
    {
        path: '/product-intro#integrated-management',
        name: 'IntegratedManagement',
        component: ProductIntroView,
        meta: {title: '一体化管理系统'}
    },
    {
        path: '/product-intro#boss-assistant',
        name: 'BossAssistant',
        component: ProductIntroView,
        meta: {title: 'BOSS助手'}
    },
    {
        path: '/product-intro#big-data-analysis',
        name: 'BigDataAnalysis',
        component: ProductIntroView,
        meta: {title: '大数据分析平台'}
    },
    {
        path: '/help-center',
        name: 'HelpCenterView',
        component: HelpCenterView,
        meta: {title: '帮助中心'}
    },
    {
        path: '/purchase-service',
        name: 'PurchaseServiceView',
        component: ProductIntroView,
        meta: {title: '解决方案'}
    },
    {
        path: '/about-us',
        name: 'AboutUsView',
        component: AboutUsView,
        meta: {title: '关于我们'}
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.afterEach((to) => {
    document.title = to.meta.title || '易企创';
});

export default router
