<template>
  <section class="carousel-section">
    <el-carousel :interval="5000" arrow="always" class="carousel-custom">
      <el-carousel-item v-for="item in items" :key="item.id">
        <div class="carousel-item-content" :style="{ backgroundImage: 'url(' + item.background + ')' }">
          <div class="carousel-text">
            <h2 class="carousel-title">{{ item.title }}</h2>
            <p class="carousel-description">{{ item.description }}</p>
            <div class="carousel-buttons" v-if="item.showButtons">
              <el-button class="trial-button">免费试用</el-button>
              <el-button class="consult-button">立即咨询</el-button>
            </div>
          </div>
          <!--          <div class="carousel-image">-->
          <!--            <img :src="item.image" alt="carousel image" />-->
          <!--          </div>-->
        </div>
      </el-carousel-item>
      <template #indicator="scope">
        <el-carousel-indicator :active="scope.active">
                <span
                    class="carousel-indicator"
                    :class="{ 'is-active': scope.active }"
                ></span>
        </el-carousel-indicator>
      </template>
      <!--&lt;!&ndash;      <template #arrow="scope">&ndash;&gt;-->
      <!--        <button-->
      <!--            v-if="scope.direction === 'left'"-->
      <!--            class="carousel-arrow carousel-arrow-left"-->
      <!--            @click="scope.handleClick"-->
      <!--        >-->
      <!--          <img src="@/assets/carousel_pre_btn.png" alt="Previous" />-->
      <!--        </button>-->
      <!--        <button-->
      <!--            v-if="scope.direction === 'right'"-->
      <!--            class="carousel-arrow carousel-arrow-right"-->
      <!--            @click="scope.handleClick"-->
      <!--        >-->
      <!--          <img src="@/assets/carousel_next_btn.png" alt="Next" />-->
      <!--        </button>-->
      <!--      </template>-->
    </el-carousel>
  </section>
</template>

<script>
export default {
  props: {
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [
        {
          id: 1,
          title: "",
          description: "",
          image: require("@/assets/carousel-image1.png"),
          background: require("@/assets/background1.png"),
        },
        {
          id: 2,
          title: "",
          description: "",
          image: require("@/assets/carousel-image2.png"),
          background: require("@/assets/background2.png"),
        },
        {
          id: 3,
          title: "",
          description: "",
          image: require("@/assets/carousel-image3.png"),
          background: require("@/assets/background3.png"),
        },
        {
          id: 4,
          title: "",
          description: "",
          image: require("@/assets/carousel-image4.png"),
          background: require("@/assets/background4.png"),
        },
        {
          id: 5,
          title: "",
          description: "",
          image: require("@/assets/carousel-image4.png"),
          background: require("@/assets/background5.png"),
          showButtons: true
        },
      ],
    };
  },
};
</script>

<style scoped>
.carousel-section {
  position: relative;
  /* //z-index: -1; */
  width: 100%;
  min-width: 1200px;
  height: 85vh;
  min-height: 680px;
  opacity: 1;
  padding-top: 0px;
}

.carousel-custom {
  width: 100%;
  min-width: 1200px;
  height: 85vh;
  margin: 0px auto;
}

:deep(.el-carousel__container) {
  height: 100%;
}

.carousel-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 100%;
  min-width: 1000px;
  padding: 0 0px;
  box-sizing: border-box;
  margin: 0 auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.carousel-text {
  max-width: 600px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.carousel-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  vertical-align: top;
  width: 100%;
  height: 70px;
  opacity: 1;
}

.carousel-description {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  text-align: left;
  vertical-align: top;
  width: 100%;
  height: 105px;
  opacity: 1;
  white-space: pre-wrap;
}

.carousel-buttons {
  margin-left: 22vh;
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.trial-button {
  width: 210px;
  height: 67px;
  opacity: 1;
  border-radius: 33.33px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1.39px 4.17px rgba(96, 108, 128, 0.05);
  background: linear-gradient(
      90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.consult-button {
  width: 210px;
  height: 67px;
  opacity: 1;
  border-radius: 44.44px;
  background: rgba(240, 242, 245, 1);
  color: rgba(128, 128, 128, 1);
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 68px;
  height: 68px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.carousel-arrow-left {
  left: 43px;
}

.carousel-arrow-right {
  right: 43px;
}

.carousel-arrow img {
  width: 100%;
  height: 100%;
}

.el-carousel__indicators .el-carousel__indicator .el-carousel__button {
  width: 12px !important;
  height: 12px !important;
  background-color: rgba(128, 128, 128, 1) !important;
  border-radius: 50% !important;
  display: inline-block !important;
  margin: 0 6px !important;
}

.el-carousel__indicators .el-carousel__indicator.is-active .el-carousel__button {
  background-color: rgba(22, 80, 139, 1) !important;
}


.carousel-image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.carousel-image img {
  width: 550px;
  height: 570px;
}
</style>
