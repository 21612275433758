<template>
  <div>
    <div class="top-style">
      <img src="../../assets/about_top.png" alt="Help Center Image" style="width: 100%; height: auto;margin: 0px;" />
    </div>
    <section class="faq-section">
      <h2 class="section-title">关于我们</h2>
      <div class="content">

        <h2>一、产品名称</h2>
        <p>中小微企业数智一体化管理云平台服务</p>

        <h2>二、服务目标</h2>
        <p>
          通过“中小微企业数智一体化管理云平台”服务，助力中小微企业实现业务运营及管理的信息化和数字化，
          以及经营管理决策的智能化。通过该平台，企业能够快速稳健地迈向数智化运营模式，降本增效，
          提升核心竞争力，从而在激烈的市场竞争中脱颖而出。
        </p>
        <p>主要服务目标包括：</p>
        <ol>
          <li>生产管理一体化：实现企业设计、生产、销售、库存、采购、OA等核心业务环节的生产管理一体化，
            通过简单易用的业务流程，提高订单交付效率，节约生产成本，提升企业内部各部门协同能力。
          </li>
          <li>
            管理决策数字化：通过数字化、智能化应用提供实时准确的数据服务，帮助管理者实时掌控所有经营情况，
            解决企业核心数据和知识严重依赖个人的局限，为企业提供科学、精准的经营管理决策支持。
          </li>
          <li>
            信息获取智能化：产学研链、生态链、价值链角度，帮助企业构建可持续发展的数智化生态体系，
            基于丰富的智能化应用，助力企业及时把握市场机会和洞察发展趋势，实时响应外部需求和提升服务协同能力。
          </li>
        </ol>

        <h2>三、服务对象</h2>
        <p>
          本服务主要面向生产制造型中小微企业，特别是那些希望实现数字化转型但缺乏足够技术、服务和资金支持的企业。
          服务对象应具备以下基础条件：
        </p>
        <ol>
          <li>
            具有明确的数字化转型需求，除内务和外来使用其他管理系统，或有现状和现有系统的计划，
            愿意投入人力资源进行数字化转型。
          </li>
          <li>
            具备一定的业务规模和市场前景（组织规模在20人以上），希望能够通过数智化管理实现可持续发展。
          </li>
          <li>拥有一定的信息化基础设施，如电脑、网络等，以便接入和使用本服务平台。</li>
        </ol>

        <h2>四、产品功能及价值</h2>
        <p>中小微企业数智一体化管理系统及服务平台具体内容包括以下核心功能：</p>
        <ol>
          <li>
            8大功能模块：产品设计模块（产品清单、物料管理、工序管理）、订单管理模块（客户管理、订单管理、
            进度跟踪、订单成本）、仓库管理模块（仓库管理、库存盘点、库存流动）、采购业务模块（供应商管理、
            订单采购、采购处理）、出入库业务模块（出入库管理）、生产管理模块（生产流程、生产加工、计件工资、
            计时工资）、成本管理模块（成本核算、试用期管理、废料管理、资产浪费）、OA管理模块（考勤管理、
            事务管理、绩效管理、综合管理）
          </li>
          <li>4大核心价值：提升订单准交率，节约生产成本，提升生产销售利润，简化运营效率。</li>
          <li>2大决策特点：一体化生产管理系统，全景式企业数据看板。</li>
          <li>1大智能引擎：全方位AI智能助理平台。</li>
        </ol>
      </div>
    </section>
    <section class="faq-section2">
      <h2 class="section-title">联系我们</h2>
      <div class="section-title-small">南京探智信息科技有限公司</div>
      <div class="contact-info">
        <!-- 地址部分 -->
        <div class="info-item">
          <img src="../../assets/Icon/address.png" alt="Location Icon" class="icon" />
          <div class="text">
            中国江苏省南京市江北新区浦珠南路30号
          </div>
        </div>
        <!-- 邮箱部分 -->
        <div class="info-item">
          <img src="../../assets/Icon/email.png" alt="Email Icon" class="icon" />
          <div class="text">
            Email: 11165858@qq.com
          </div>
        </div>
        <!-- QQ部分 -->
        <div class="info-item">
          <img src="../../assets/Icon/qq.png" alt="QQ Icon" class="icon" />
          <div class="text">
            QQ: 11165858
          </div>
        </div>



      </div>
      <img src="../../assets/about_bottom.png" alt="Help Center Image" style="width: 80%;margin: 50px 10%" />
    </section>
  </div>

</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style scoped>
h1,
h2 {
  color: #0056b3;
  margin-top: 20px;
}

p {
  margin: 10px 0;
}

ol {
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.top-style {
  width: 100%;
  margin-top: 110px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}

.faq-section {
  background-color: #f7f9fa;
  margin: 0px;
  /* text-align: center; */
  padding-top: 50px;
}

.faq-section2 {
  background-color: #ffffff;
  margin: 0px;
  /* text-align: center; */
  padding-top: 50px;
}


.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  background: linear-gradient(90deg,
      rgba(22, 80, 139, 1) 0%,
      rgba(35, 133, 198, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 0px
}

.section-title-small {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.content {
  padding: 20px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  color: #333;
  margin-left: 10%;
  width: 80%;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  /* 子元素均匀分布 */
  align-items: center;
  margin: 20px 0;
  width: 80%;
  margin-left: 10%;
  margin-top: 50px
}

/* 每个信息块的样式 */
.info-item {
  display: flex;
  align-items: center;
  /* 图标和文字垂直居中 */
  gap: 15px;
}

/* 图标部分 */
.icon {
  width: 24px;
  height: 24px;
}

/* 文字部分 */
.text {
  font-size: 18px;
  color: #333;
}
</style>
