<template>
  <div class="hero-image-container">
    <img src="@/assets/boss_hero_bg.png" alt="Hero Background" class="hero-image"/>
  </div>
</template>

<script>
export default {
  name: "HeroImage"
};
</script>

<style scoped>
.hero-image-container {
  width: 100%;
  overflow: hidden; /* 确保图片不会超出容器 */
}

.hero-image {
  width: 100%;
  height: auto; /* 保持图片比例 */
  display: block; /* 移除图片底部的空白间隙 */
}
</style>
