<template>
  <div>
    <AppHeader/>
    <ProductIntroContent/>
    <AppFooter/>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import ProductIntroContent from '../components/ProductIntro/ProductIntroContent.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  name: 'ProductIntroView',
  components: {
    AppHeader,
    ProductIntroContent,
    AppFooter
  }
}
</script>
